<template>
  <v-container class="d-flex flex-column pa-8">
    <div class="header-top">
      <h2 class="view-title">Keyword Research</h2>
    </div>
    <v-card>
      <v-card-text>
        <v-form ref="form" class="form-report">
          <v-checkbox v-model="checkbox" label="Paid Keywords Report" class="mt-0" />
          <v-text-field
            v-model="report_name"
            label="Report Name"
            outlined
            placeholder="Report Name"
            hint="Report Name"
            persistent-hint
            hide-details="auto"
            :rules="[validators.required]"
          />
          <div v-if="checkbox === false">
            <v-row>
              <v-col col="12" md="6">
                <v-text-field
                  v-model="client_url"
                  label="Client URL"
                  hint="Enter the URL of the Client"
                  persistent-hint
                  outlined
                  placeholder="Client URL"
                  hide-details="auto"
                  :rules="[validators.required, validators.urlValidator]"
                />
              </v-col>
              <v-col col="12" md="6">
                <v-text-field
                  v-model="client_brand"
                  label="Brand Term"
                  hint="Enter a branded term for this comparison URL (E.g. 'Google' for google.com)"
                  persistent-hint
                  outlined
                  placeholder="Brand Term"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
            <div v-for="(item, i) in competitor_urls" :key="i">
              <v-row>
                <v-col col="12" md="6">
                  <v-text-field
                    v-model="item.url"
                    label="Comparsion URL"
                    hint="Enter the URL of the competitor"
                    persistent-hint
                    outlined
                    placeholder="Comparsion URL"
                    :append-icon="item.remove ? 'mdi-close' : ''"
                    hide-details="auto"
                    :rules="[validators.required, validators.urlValidator]"
                    @click:append="removeUrl(i)"
                  />
                </v-col>
                <v-col col="12" md="6">
                  <v-text-field
                    v-model="item.brand"
                    label="Brand Term"
                    hint="Enter a branded term for this comparison URL (E.g. 'Google' for google.com)"
                    persistent-hint
                    outlined
                    placeholder="Brand Term"
                    :append-icon="item.remove ? 'mdi-close' : ''"
                    hide-details="auto"
                    @click:append="removeUrl(i)"
                  />
                </v-col>
              </v-row>
            </div>
            <v-select
              v-if="checkbox"
              v-model="match_type"
              :items="items"
              item-text="label"
              item-value="value"
              outlined
              hint="Keywords match type"
              persistent-hint
            />
          </div>
          <div v-else>
            <v-text-field
              v-model="client_url"
              label="Client URL"
              hint="Enter the client URL you want to compare"
              persistent-hint
              outlined
              placeholder="Client URL"
              hide-details="auto"
              :rules="[validators.required, validators.urlValidator]"
            />
            <div v-for="(url, i) in competitor_urls" :key="i">
              <v-text-field
                v-model="url.value"
                label="Comparsion URL"
                hint="Enter the URL of the competitor"
                persistent-hint
                outlined
                placeholder="Comparsion URL"
                :append-icon="url.remove ? 'mdi-close' : ''"
                hide-details="auto"
                :rules="[validators.required, validators.urlValidator]"
                @click:append="removeUrl(i)"
              />
            </div>
            <v-select
              v-if="checkbox"
              v-model="match_type"
              :items="items"
              item-text="label"
              item-value="value"
              outlined
              hint="Keywords match type"
              persistent-hint
            />
          </div>

          <div>
            <v-btn text @click="addUrl" class="btn-more" v-if="competitor_urls.length < 5">
              <v-icon dark left> mdi-plus </v-icon> Add a Competitor URL
            </v-btn>
          </div>

          <v-btn color="primary" class="btn-submit" @click="submit"> Create Report </v-btn>

          <v-alert v-if="errors" class="mt-4" color="error" text>
            <p class="font-weight-semibold mb-1">Messages</p>
            <p v-for="(error, i) in errors" :key="i" class="text-sm mb-0">
              {{ error }}
            </p>
          </v-alert>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>


<script>
import { mapActions } from 'vuex'
import { mdiClose } from '@mdi/js'
import { required, urlValidator } from '@core/utils/validation'

export default {
  components: {
    mdiClose,
  },
  data() {
    return {
      loading: false,
      loadingLimit: true,

      errors: null,

      snackbar: false,
      timeout: 2500,
      form: null,
      data: {},
      checkbox: false,
      items: [
        { label: 'Phrase', value: 'phrase' },
        { label: 'Exact', value: 'exact' },
        { label: 'Broad', value: 'broad' },
      ],
      report_name: '',
      client_url: '',
      client_brand: '',
      competitor_urls: [],
      match_type: { label: 'Phrase', value: 'phrase' },

      validators: {
        required,
        urlValidator,
      },
    }
  },
  watch: {
    checkbox: {
      handler(value) {
        this.client_url = ''
        this.client_brand = ''
        this.competitor_urls = []
      },
    },
  },
  methods: {
    ...mapActions({
      new: 'keyword_research/new',
      newRankedKeyword: 'keyword_research/newRankedKeyword',
      setSnackbar: 'snackbar/set',
    }),
    goToPath(path) {
      this.$router.push({ path })
    },
    addUrl() {
      const index = this.competitor_urls.length
      this.$set(this.competitor_urls, index, { value: '', remove: true })
    },
    removeUrl(index) {
      this.competitor_urls.splice(index, 1)
    },

    submit() {
      if (this.$refs.form.validate()) {
        let urls = []
        if (this.checkbox) {
          urls = this.competitor_urls.map(item => item.value)

          const data = {
            report_type: this.checkbox,
            report_name: this.report_name,
            client_url: this.client_url,
            competitor_urls: urls,
            match_type: this.match_type.value,
          }

          this.newReport(data)
        } else {
          urls = this.competitor_urls.map(item => ({
            url: item.url,
            brand: item.brand ? item.brand : '',
          }))

          const data = {
            report_type: this.checkbox,
            report_name: this.report_name,
            client_url: this.client_url,
            client_brand: this.client_brand ? this.client_brand : '',
            competitor_urls: urls,
            match_type: this.match_type.value,
          }
          this.newRankedReport(data)
        }
      }
    },

    async newReport(data) {
      this.loading = false
      this.snackbar = true
      await this.new(data)
        .then(resp => {
          if (resp && resp.data && resp.data.kwr) {
            this.data = resp.data
            const path = `/kw-research/await/${resp.data.kwr.id}`
            this.$router.push({ path })
          }
        })
        .catch(error => {
          console.log(error)
          if (error.response.data && error.response.data.status === 'error') {
            this.errors = error.response.data.errors
          } else {
            this.errors = null
          }
          this.setSnackbar({
            status: true,
            text: 'Failed to create report.',
            color: 'error',
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async newRankedReport(data) {
      this.loading = false
      this.snackbar = true
      await this.newRankedKeyword(data)
        .then(resp => {
          if (resp && resp.data && resp.data.kwr) {
            this.data = resp.data
            const path = `/kw-research/await/${resp.data.kwr.id}`
            this.$router.push({ path })
          }
        })
        .catch(error => {
          console.log(error)
          if (error.response.data && error.response.data.status === 'error') {
            this.errors = error.response.data.errors
          } else {
            this.errors = null
          }
          this.setSnackbar({
            status: true,
            text: 'Failed to create report.',
            color: 'error',
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style scoped>
.header-top {
  margin-bottom: 20px;
}
.btn-more {
  padding: 0px !important;
  word-break: break-word !important;
}

.btn-submit {
  margin-top: 20px;
}
</style>
